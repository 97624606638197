exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-laravel-10-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/blog/laravel-10.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-laravel-10-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-laravel-transactions-why-and-how-to-use-them-with-real-life-use-cases-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/blog/laravel-transactions-why-and-how-to-use-them-with-real-life-use-cases.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-laravel-transactions-why-and-how-to-use-them-with-real-life-use-cases-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-mastering-the-terminal-for-web-developers-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/blog/mastering-the-terminal-for-web-developers.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-mastering-the-terminal-for-web-developers-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-web-development-basics-the-ultimate-2023-roadmap-for-beginners-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/blog/web-development-basics-the-ultimate-2023-roadmap-for-beginners.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-blog-web-development-basics-the-ultimate-2023-roadmap-for-beginners-mdx" */)
}

